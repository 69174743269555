<template>
  <div class="page-content">
    <div class="section">
      <div class="container-fluid">

        <div class="row">
          <div class="col-12 col-lg-4 col-xl-3">
            <div class="guide-article__top">
              <div class="guide-article__top-img">
                <img v-if="false" src="/assets/search.png" alt="Icon der Suchfunktion">
              </div>
              <div class="guide-article__top-info">
                <h2 class="mb-2">Suche</h2>
              </div>
            </div>
            <div class="mb30">
              <keyword-search :initialValue="searchWord" @searchWordChanged="searchWordChanged"/>
            </div>

          </div>

          <div class="col-12 col-xl-9 ml-auto main-content-section">

            <div v-show="numberOfResults==0" class="search-content text-center">
              <div v-if="searchWord==''" class="search-content text-center">
                <img v-if="false" src="/assets/search.png" alt="Search">
                <h5 class="mt20">Gib einen Suchbegriff ein.</h5>
              </div>
              <div v-else-if="showResults && numberOfResults== 0 && searchWord!==''" class="search-content text-center">
                <img v-if="false" src="/assets/no-result.png" alt="Keine Suchergebnisse">
                <h5 class="mt20">Keine Ergebnisse für</h5>
                <h5>{{ `"` + searchWord + `"` }}</h5>
                <p class="mt20">Bitte gib einen anderen Suchbegriff ein.</p>
              </div>
            </div>

            <div v-show="numberOfResults>0" class="search-results">
              <div class="container-fluid" v-if="showResults && searchWord!==''">
               <!-- <content-section @numberOfResults="receiveNumberOfResults" title="Inspirationen" :searchWord="searchWord" :contentId="parseInt($offerId)" :openInNewTab="true"/> --> 
                <content-section @numberOfResults="receiveNumberOfResults" title="Events" :searchWord="searchWord" :contentId="parseInt($offerId)" :openInNewTab="true"/>
                <content-section @numberOfResults="receiveNumberOfResults" title="Locations" :searchWord="searchWord" :contentId="parseInt($poiId)" :openInNewTab="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getContentsOfType } from '@/api/content';
  import { showLoader, hideLoader, isset } from '@/utils/helpers';

  export default {
    name: 'Suche',
    components: {
      KeywordSearch: () => import('@/components/controls/KeywordSearch'),
      ContentSection: () => import('./components/ContentSection.vue'),
    },
    data() {
     return {
      searchWord: '',
      showResults: false,
      awaitingSearch: false,
      numberOfResults: 0
    }
  },
  watch: {
    '$route.query.keyword': {
      handler: function(search) {
       this.searchWordChanged(search);
     },
     deep: true,
     immediate: true
   },
 },
 computed: {
  keyword(){
    return this.$route.query.keyword;
  },
},
methods: {
  showLoader,
  hideLoader,
  receiveNumberOfResults(number){
    this.numberOfResults = this.numberOfResults+number;
  },
  searchWordChanged(word){
    this.showResults = false;
    this.searchWord = word;
    if (!this.awaitingSearch) {
     setTimeout(() => {
      this.showResults = true;
      this.numberOfResults = 0;
      this.awaitingSearch = false;
    }, 1000);
   }
   this.awaitingSearch = true;
 },
}
}
</script>
<style lang="scss" scoped>
.section {
	padding-top: 100px;
}

</style>